<template>
  <v-flex v-model="bar3d_general" class="chart_properties dark_theme" wrap>
    <div class="chart_properties_header">
      <span>Navigation</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents type_noborder">
      <div class="header">Name</div>
      <v-flex class="control">
        <input v-model="Name" type="text" placeholder="Chart Name" class="type100" />
      </v-flex>
    </div>
    <div v-if="defineNav" class="chart_properties_contents">
      <div class="header">Navigation</div>
      <v-flex class="control">
        <div>
          <div class="type_inner type_tree">
            <v-select
              v-model="PhaseCode"
              class="tree_node"
              item-text="phaseName"
              item-value="phaseCode"
              label="Phase"
              placeholder="- Required"
              required
              attach
              :items="phaseOptions"
              :menu-props="{contentClass:'single_select'}"
              :rules="[v => !!v || 'Item is required']"
              @input="onPhaseChanged"
            ></v-select>
            <v-select
              v-model="GroupCode"
              class="tree_node"
              item-text="groupName"
              item-value="groupCode"
              label="Menu Group"
              placeholder="- Required"
              required
              attach
              :items="groupOptions"
              :menu-props="{contentClass:'single_select'}"
              :rules="[v => !!v || 'Item is required']"
              @input="onGroupChanged"
            ></v-select>
            <v-select
              v-model="ItemCode"
              class="tree_node"
              item-text="itemName"
              item-value="itemCode"
              label="Menu Item"
              placeholder="- Required"
              required
              attach
              :items="itemOptions"
              :menu-props="{contentClass:'single_select'}"
              :rules="[v => !!v || 'Item is required']"
              @input="onItemChanged"
            ></v-select>
            <v-select
              v-model="SubItem"
              attach
              class="tree_end"
              item-text="subItemName"
              item-value="idx"
              label="Sub Item"
              placeholder="- Optional"
              :items="subItemOptions"
              :menu-props="{contentClass:'single_select'}"
            ></v-select>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">Import Property Data</div>
      <v-flex class="control">
        <div class="column align_end">
          <select v-model="refcode" class="type100">
            <option v-for="item in importOptions" :key="item.index" :value="item.code">{{item.name}}</option>
          </select>
          <a class="type01" @click="importItem">Import Data</a>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import { ChartLibraryService, SystemCodeService, SystemService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"

export default {
  name: 'j-chart-tab-navigation',
  mixins: [
    QueryValidationMixin
  ],
  props: {
    formMode: String,
    defineNav: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    systemCodeService: null,
    systemService: null,
    // options -----------------------
    phaseOptions: [],
    groupOptions: [],
    itemOptions: [],
    subItemOptions: [],
    filterOptions: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" }
    ],
    dbtypeOptions: [
      { text: "Query", value: "Q" },
      { text: "Store Procedure", value: "P" }
    ],
    // rules -------------------------
    codeRules: [v => (!!v && v != 'ALL') || "Required"],
    defaultRules: [v => !!v || "Required"],
    // Other component owned ---------
    validated: {
      QSummary: { valid: true, message: '' },
    },

    importOptions: [],
    refcode: '',

    // temporal data
    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit("sysenv/codePropagate", value) }
    },

    PhaseCode: {
      get() { return this.chartItem.PhaseCode },
      set(val) { this.setChartItem({ PhaseCode: val }) }
    },
    GroupCode: {
      get() { return this.chartItem.GroupCode },
      set(val) { this.setChartItem({ GroupCode: val }) }
    },
    ItemCode: {
      get() { return this.chartItem.ItemCode },
      set(val) { this.setChartItem({ ItemCode: val }) }
    },
    SubItem: {
      get() { return this.chartItem.SubItem },
      set(val) { this.setChartItem({ SubItem: val }) }
    },
    Name: {
      get() { return this.chartItem.Name },
      set(val) { this.setChartItem({ Name: val }) }
    },
    Filtering: {
      get() { return this.chartItem.Filtering },
      set(val) { this.setChartItem({ Filtering: val }) }
    },
    CatCode: {
      get() { return this.chartItem.CatCode },
      set(val) { this.setChartItem({ CatCode: val }) }
    },
    ChartType: {
      get() { return this.chartItem.ChartType },
      set(val) { this.setChartItem({ ChartType: val }) }
    },
    ChartDb: {
      get() { return this.chartItem.ChartDb },
      set(val) { this.setChartItem({ ChartDb: val }) }
    },
    ChartNo: {
      get() { return this.chartItem.ChartNo },
      set(val) { this.setChartItem({ ChartNo: val }) }
    },
    DbAccess: {
      get() { return this.chartItem.DbAccess },
      set(val) { this.setChartItem({ DbAccess: val }) }
    },
    QApplied: {
      get() { return this.chartItem.QApplied },
      set(val) { this.setChartItem({ QApplied: val }) }
    },
    QSummary: {
      get() { return this.chartItem.QSummary },
      set(val) { this.setChartItem({ QSummary: val }) }
    },
    QDatagrid: {
      get() { return this.chartItem.QDatagrid },
      set(val) { this.setChartItem({ QDatagrid: val }) }
    },
    QSp: {
      get() { return this.chartItem.QSp },
      set(val) { this.setChartItem({ QSp: val }) }
    },
    JsonString: {
      get() { return this.chartItem.JsonString },
      set(val) { this.setChartItem({ JsonString: val }) }
    },
    JsonProps: {
      get() { return this.chartItem.JsonProps },
      set(val) { this.setChartItem({ JsonProps: val }) }
    },
    Description: {
      get() { return this.chartItem.Description },
      set(val) { this.setChartItem({ Description: val }) }
    },
  },
  watch: {
    ChartType(val) {
      if(!val) return
      this.getImportOptions()
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemCodeService = new SystemCodeService()
    this.systemService = new SystemService()
  },
  mounted() {
    this.systemService.getNavPhase(res => {
      if (!res) this.phaseOptions = []
      else this.phaseOptions = res
    })
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartData', 'setChartItem']),

    onPhaseChanged(phaseCode) {
      this.groupOptions = []
      if(phaseCode == 'ALL') return

      this.systemService.getNavGroup(phaseCode, res => {
        if (res) this.groupOptions = res
      })
    },
    onGroupChanged(groupCode) {
      this.itemOptions = []
      if(groupCode == 'ALL') return

      this.systemService.getNavItem(
        res => {
          if (res) this.itemOptions = res
        },
        this.PhaseCode,
        groupCode
      )
    },
    onItemChanged(itemCode) {
      this.subItemOptions = [
        {
          idx: 0,
          subItemName: 'None',
        }
      ]
      if (itemCode == "ALL") return
      if (!this.PhaseCode || !this.ItemCode) return

      this.systemService.getNavSubItem(
        res => {
          if (res) this.subItemOptions = [ ...this.subItemOptions, ...res ]
        },
        this.PhaseCode,
        this.ItemCode
      )
    },
    getImportOptions() {
      let catCode_ = this.chartItem.CatCode
      let chartType_ = this.chartItem.ChartType

      this.systemCodeService.chartOptions(catCode_, chartType_, (res) => {
        this.importOptions = res
      })
    },
    importItem() {
      if(!this.refcode) return
      this.$emit('import-item', this.refcode)
    },
    setData(mode) {
      if (mode == __C.FORM.EDIT_MODE_NEW) {
        this.PhaseCode = this.PhaseCode || this.codePropagated.parentCode
        this.GroupCode = this.GroupCode || this.codePropagated.groupCode
        this.ItemCode = this.ItemCode || this.codePropagated.code

        this.onPhaseChanged(this.PhaseCode)
        this.onGroupChanged(this.GroupCode)
        this.onItemChanged(this.ItemCode)

      } else {
        if(!this.refcode) this.refcode = this.chartItem.ChartNo

        this.onPhaseChanged(this.PhaseCode)
        this.onGroupChanged(this.GroupCode)
        this.onItemChanged(this.ItemCode)
      }

      this.getImportOptions()
    }
  }
}
</script>
